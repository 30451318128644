import { useForm } from 'react-hook-form'
import { IoIosArrowBack } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import { useLockBodyScroll } from '@uidotdev/usehooks'

import logo_light from '../img/logo.png'
import logo_dark from '../img/logo2.png'
import delivery from '../img/delivery.png'
import restaurant from '../img/restaurant.png'
import takeAway from '../img/take-away.png'

const Options = ({ setCartOn }) => {
  const navigate = useNavigate()
  useLockBodyScroll()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    console.log(data)
    if (data.delivery === 'in_restaurant') {
      navigate('/in-restaurant')
    } else if (data.delivery === 'deliver_home') {
      navigate(`/transport`)
    } else {
      navigate(`/recieve-from-store`)
    }
  }

  return (
    <div className='fixed inset-0'>
      <div className='w-full fixed z-[50] top-0 bg-white max-w-md mx-auto h-16 top-auto shadow-md py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid grid-cols-12 justify-start items-center'>
          <Link
            to='/'
            className={`col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2 pr-4`}
          >
            <img
              src={logo_light}
              alt='logo'
              className=' w-[56px] h-[57px] dark:hidden'
            />
            <img
              src={logo_dark}
              alt='logo'
              className=' w-[56px] h-[57px] hidden dark:block'
            />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-center text-main-900 dark:text-white'>
              <span className='inline-block transform translate-y-1 mx-0.5 text-brown-400'>
                خيارات التسليم
              </span>
              <span className='font-extrabold text-md text-center text-main-900 dark:text-white overflow-hidden'>
                {' '}
                Delivery options
              </span>
            </h1>
          </Link>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 rounded-full text-main-900 hover:bg-main-900 hover:text-white dark:text-white transition p-2 cursor-pointer ml-4'
          title='رجوع'
          onClick={() => {
            navigate(-1) 
            setCartOn(true)}}
        />
      </div>
      <div style={{ overflow: 'hidden' }}>
        <form
          className='fixed max-w-md w-full flex flex-col pt-[70px] gap-3 px-4 bg-white animateItems dark:bg-gray-700'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='flex flex-col'>
            {errors?.delivery && (
              <small className='text-red-500 text-center pt-3'>
                {errors.delivery.message}
              </small>
            )}
            <div className='flex flex-col items-center justify-center gap-8 py-4'>
              <div>
                <input
                  type='radio'
                  className='hidden peer'
                  id='in_restaurant'
                  value='in_restaurant'
                  {...register('delivery', {
                    required: {
                      value: true,
                      message: '" الرجاء تحديد خيار واحد على الأقل "',
                    },
                  })}
                />
                <label
                  className={`peer-checked:border-main-900 peer-checked:bg-main-900 text-gray-700 peer-checked:text-white flex flex-col items-center justify-center gap-3 border w-44 h-28 p-2 rounded-md dark:text-white cursor-pointer ${
                    errors.delivery ? 'border-red-500' : 'border-gray-400'
                  }`}
                  htmlFor='in_restaurant'
                >
                  {/* <BsShop className='text-5xl' /> */}
                  <img src={restaurant} alt='delivery' className='w-16 h-16' />
                  <span className='text-sm'>القسم المحلي</span>
                </label>
              </div>
              <div>
                <input
                  type='radio'
                  className='hidden peer'
                  id='deliver_home'
                  value='deliver_home'
                  {...register('delivery', {
                    required: {
                      value: true,
                      message: '" الرجاء تحديد خيار واحد على الأقل "',
                    },
                  })}
                />
                <label
                  className={`peer-checked:border-main-900 peer-checked:bg-main-900 text-gray-700 peer-checked:text-white flex flex-col items-center justify-center gap-3 border p-2 w-44 h-28 rounded-md dark:text-white cursor-pointer ${
                    errors.delivery ? 'border-red-500' : 'border-gray-400'
                  }`}
                  htmlFor='deliver_home'
                >
                  {/* <BsBicycle className='text-5xl' /> */}
                  <img src={delivery} alt='restaurant' className='w-16 h-16' />
                  <span className='text-sm'>توصيل للمنزل</span>
                </label>
              </div>
              <div>
                <input
                  type='radio'
                  className='hidden peer'
                  id='bike'
                  value='bike'
                  {...register('delivery', {
                    required: {
                      value: true,
                      message: '" الرجاء تحديد خيار واحد على الأقل "',
                    },
                  })}
                />
                <label
                  className={`peer-checked:border-main-900 peer-checked:bg-main-900 text-gray-700 peer-checked:text-white flex flex-col dark:text-white items-center justify-center gap-3 border p-2 w-44 h-28 rounded-md cursor-pointer ${
                    errors.delivery ? 'border-red-500' : 'border-gray-400'
                  }`}
                  htmlFor='bike'
                >
                  {/* <BsBagCheck className='text-5xl' /> */}
                  <img src={takeAway} alt='takeAway' className='w-16 h-16' />
                  <span className='text-sm'>استلام من الفرع</span>
                </label>
              </div>
            </div>
          </div>
          <button
            className='bg-main-900 font-semibold hover:bg-main-900 transition text-white py-2 w-full rounded-full'
            type='submit'
          >
            التالي
          </button>
        </form>
      </div>
    </div>
  )
}

export default Options
